import { Box, Button, HStack } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import CenterLayout from '@/components/shared/Layout/CenterLayout';
import { Session } from 'next-auth';
import { useBrand } from '../hooks/useBrand';
import { useKeys } from '../contexts/KeysContext';
import { signIn } from 'next-auth/react';
import { useLanguage } from '@/hooks/useLanguage';

export { getServerSideProps } from '@/utils/auth';

const Login = ({ session }: { session: Session | null }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const brand = useBrand();
  const keys = useKeys();

  useLanguage(brand.languages);

  const requiresAuth = keys.getKey('brand') !== 'pdc';

  useEffect(() => {
    const callbackUrl = router.query.callbackUrl as string;

    if (requiresAuth && session && router.query?.error !== 'domain') {
      router.push(callbackUrl ? callbackUrl + window.location.hash : '/');
    }
  }, [session, router, requiresAuth]);

  return session && router.query?.error !== 'domain' ? null : (
    <CenterLayout languages={['en']}>
      <>
        <Box my={4} textAlign="center">
          {t(`common.login.disclaimer.${brand.brand}`)}
        </Box>
        {router.query?.error && (
          <Box my={4} textAlign="center" color="red.500">
            {router.query?.error === 'domain' && t('common.login.error.domain')}
            <br />
            Used email: {session?.user?.email}
          </Box>
        )}
        <HStack mt={6}>
          <Button flex={1} onClick={() => signIn('google')}>
            {t('common.login.google')}
          </Button>
        </HStack>
      </>
    </CenterLayout>
  );
};

export default Login;
